import React from 'react';
import { Space,Button, Tooltip} from 'antd';
import zoomIn from '../../../asesst/Icons/interactivemap/icon/zoom-in.svg'
import zoomOut from '../../../asesst/Icons/interactivemap/icon/zoom-out.svg'

export const ZoomTools: React.FC<any> = () => {
    return (
        <React.Fragment>
            <Space
                direction='vertical'
                style={{
                    position: 'absolute',
                    right: '15px',
                    bottom: 140,
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <Tooltip 
                    title={<p style={{color:'black'}}>location</p>}
					placement="right"
					color='white'
                    >
                    <Button
                       id='end-point'
                        style={{
                            backgroundColor: 'white', // Ubah warna tombol menjadi putih
                            color: 'black', // Ubah warna teks menjadi hitam
                            height: "50px",
                            width: "50px",
                            borderRadius:'0px',
                            display: 'flex',
                            justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                            alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                        }}
                     
                    >
                    <img 
                        src={zoomIn}
                        alt="Button Icon" 
                    />
                    </Button>
                </Tooltip>
            </Space>
            <Space
                direction='vertical'
                style={{
                    position: 'absolute',
                    right: '15px',
                    bottom: 90,
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <Tooltip 
                    title={<p style={{color:'black'}}>location</p>}
					placement="right"
					color='white'
                    >
                    <Button
                       id='end-point'
                        style={{
                            backgroundColor: 'white', // Ubah warna tombol menjadi putih
                            color: 'black', // Ubah warna teks menjadi hitam
                            height: "50px",
                            width: "50px",
                            borderBottomLeftRadius:'12px',
                            borderBottomRightRadius:'12px',
                            borderTopLeftRadius:'0px',
                            borderTopRightRadius:'0px',
                            display: 'flex',
                            justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                            alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                        }}
                     
                    >
                    <img 
                        src={zoomOut}
                        alt="Button Icon" 
                    />
                    </Button>
                </Tooltip>
            </Space>
        </React.Fragment>
    )
}