import React from 'react';
import { EnvironmentOutlined, AimOutlined, ArrowsAltOutlined } from '@ant-design/icons';
import { FaBriefcase } from 'react-icons/fa'; // Example icon
import { formatNumberWithDots } from '../../../component/newmap/helper/formatNumber';
interface ResultCatalogProps {
    onBackClick: () => void;
    metaData: any
  }
  
export const DrawerSeeData: React.FC<ResultCatalogProps> = ({ onBackClick, metaData }) => {
  return (
    <React.Fragment>
      {/* Back Arrow */}
      <div style={{ marginBottom: '20px', cursor: 'pointer' }}>
        <span onClick={onBackClick} style={{ fontSize: '18px', fontWeight: 'bold' }}>← Result Location</span>
      </div>

      <div
          style={{
            display: 'flex',
            alignItems: 'center',
            background: '#142943',
            padding: '5px',
            borderRadius: '12px',
            marginBottom: '10px',
            marginTop: '10px',
            paddingTop:'10px',
            paddingBottom:'10px',
            border: '1px solid black', // Set border color to white
            paddingLeft:'10px',
            paddingRight:'10px',
            cursor: 'pointer',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for 
          }}
        >
          <FaBriefcase size={30} style={{ marginRight: '20px', color: '#fff' }} />
          <div style={{width: "166px"}}>
            <h4 style={{ marginBottom: '10px' }}>{metaData?.alias}</h4>
            <p> {metaData?.description}</p>
          </div>
          {/* <IoChevronForwardOutline size={30} style={{ color: '#fff' }} /> */}
        </div>

      <div
        style={{
          padding: '15px',
          borderRadius: '12px',
        }}
      >
        <EnvironmentOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
        <span style={{ fontWeight: 'bold' }}>Location Address</span>
        <p style={{ margin: '5px 0 0' }}>
          {metaData?.address}
        </p>
      </div>
      <div
        style={{
          padding: '15px',
          borderRadius: '12px',
        }}
      >
        <EnvironmentOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
        <span style={{ fontWeight: 'bold' }}>Position</span>
        <p style={{ margin: '5px 0 0' }}>
        {metaData?.centroid[1]},<br /> {metaData?.centroid[0]}
        </p>
      </div>
      <div
        style={{
          padding: '15px',
          borderRadius: '12px',
        }}
      >
        <AimOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
        <span style={{ fontWeight: 'bold' }}>Size of Area</span>
        <p style={{ margin: '5px 0 0' }}>
        {formatNumberWithDots(metaData?.area)} m2
        </p>
      </div>
      <div
        style={{
          padding: '15px',
          borderRadius: '12px',
        }}
      >
        <ArrowsAltOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
        <span style={{ fontWeight: 'bold' }}>Squares</span>
        <p style={{ margin: '5px 0 0' }}>
        {formatNumberWithDots(metaData?.total_grid)}
        </p>
      </div>
      
    </React.Fragment>
  );
};
