import maplibregl, { DataDrivenPropertyValueSpecification } from 'maplibre-gl';
// import { ExpressionSpecification } from 'maplibre-gl';
import { getStorage, ref, getDownloadURL, getBytes } from 'firebase/storage';
import GridParser from './grid';
import { loadParquet } from '../../queue/parquet';
import * as turf from '@turf/turf';
import { PropertyData } from '../../../../../interface/parquete.interface';
import { filterAllBySeriesName, filterByTimeOfDay } from '../../data/group.layer.series';
import { sortArray } from '../../short';

export const addGeoJsonLayerParquet = async (
  map: maplibregl.Map,
  storageParqueteData: any,
  storageMetaData: any,
  setDataLayerGroup: (layerGroup: any) => void, // Update type if needed
  dataSeries: string,
  setIsSeries: (isSeries: boolean) => void,
  colorGroup: any,
  onClickFeature?: (feature: any) => void,
  fillOpacity: number = 0.5,
  setIsLoading?: (isLoading: boolean) => void,
) => {

  const storage = getStorage();
  const grid_lib = new GridParser();
  const listRef = ref(storage, storageParqueteData);
  const listRefMetaData = ref(storage, storageMetaData);


  try {
    if (setIsLoading) setIsLoading(true); // Start loader
    const downloadUrl = await getDownloadURL(listRef);
    const partData: unknown[] = await loadParquet(downloadUrl);
    const fileBytes = await getBytes(listRefMetaData);
    const textData = new TextDecoder().decode(fileBytes);
    const jsonData = JSON.parse(textData);
    const propertyDataList = partData as PropertyData[];

    setIsSeries(jsonData?.[0]?.isSeries)
    // Tentukan grup berdasarkan kondisi isSeries
    let groupedData: any;
    let layerGroup = [];
    if (jsonData?.[0]?.isSeries === true) {
      // groupedData = propertyDataList
      groupedData = filterByTimeOfDay(propertyDataList, dataSeries.toLocaleLowerCase());
      layerGroup = filterAllBySeriesName(jsonData, dataSeries);
    } else {
      groupedData = propertyDataList
      layerGroup = jsonData
    }
    // console.log(colorGroup?.color[4]);

    setDataLayerGroup(layerGroup);
    // Hapus layer dan source yang ada sebelumnya
    const layers = map.getStyle().layers || [];
    const sources = map.getStyle().sources || {};

    layers.forEach((layer) => {
      if (layer.id.startsWith('layer-')) {
        if (map.getLayer(layer.id)) {
          map.removeLayer(layer.id);
        }
      }
    });

    Object.keys(sources).forEach((sourceId) => {
      if (sourceId.startsWith('source-')) {
        if (map.getSource(sourceId)) {
          map.removeSource(sourceId);
        }
      }
    });

    const allFeatures: any[] = [];

    layerGroup.forEach((group: any) => {
      const groupFeatures = groupedData.map((item: { gid: string, color: string }) => ({
        type: 'Feature',
        properties: {
          ...item,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [grid_lib._gid_to_geometry(item.gid)],
        },
      }));

      allFeatures.push(...groupFeatures);
      // console.log(...groupFeatures);

      // Buat source dan layer baru berdasarkan gid
      const sourceId = `source-${group.columnName}`; // Use `columnName` or any other identifier from layerGroup
      const layerId = `layer-${group.columnName}`;
      map.addSource(sourceId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: groupFeatures,
        },
      });
      const shortBins = sortArray(group?.bins)
      const colorStops: any[] = ['interpolate', ['linear'], ['get', `${group?.columnName}`]];
      shortBins.forEach((bin, index) => {
        const color = colorGroup?.color[index] || '#000'; // Use default color if not enough colors
        colorStops.push(bin, color);
      });
      map.addLayer({
        id: layerId,
        type: 'fill',
        source: sourceId,
        minzoom: 2,
        paint: {
          'fill-color': colorGroup.type === 'gradient'
            ? [
              'interpolate',
              ['linear'],
              ['get', `${group?.columnName}`],
              group?.min, colorGroup?.color[0] || '#6CA380',  // Greenish color at the lower bound
              (group?.min + (group?.max - group?.min) * 0.1), colorGroup?.color[1] || '#a0b882',
              (group?.min + (group?.max - group?.min) * 0.2), colorGroup?.color[2] || '#d3cb84',
              (group?.min + (group?.max - group?.min) * 0.3), colorGroup?.color[3] || '#e1a76d',
              (group?.min + (group?.max - group?.min) * 0.4), colorGroup?.color[4] || '#f09c5e',
              (group?.min + (group?.max - group?.min) * 0.5), colorGroup?.color[5] || '#f48752',
              (group?.min + (group?.max - group?.min) * 0.6), colorGroup?.color[6] || '#ec624a',
              (group?.min + (group?.max - group?.min) * 0.7), colorGroup?.color[7] || '#d85546',
              (group?.min + (group?.max - group?.min) * 0.8), colorGroup?.color[8] || '#c64f36', // Reddish color for upper bound
              group?.max, colorGroup?.color[9] || '#c64f36' // Keep the same color for the maximum value
            ]
            :  colorStops as DataDrivenPropertyValueSpecification<string>,
          'fill-opacity': 0.8,
          'fill-outline-color': 'gray',
        },
      });
      map.on('mousemove', layerId, (e) => {
        if (e.features && e.features.length > 0) {
          map.getCanvas().style.cursor = 'pointer';
        }
      });

      map.on('mouseleave', layerId, () => {
        map.getCanvas().style.cursor = '';
        if (map.getLayer(`${layerId}-highlight`)) {
          map.removeLayer(`${layerId}-highlight`);
        }
      });

      map.on('click', layerId, (e) => {
        if (e.features && e.features.length > 0) {
          const feature = e.features[0];
          // console.log(feature.properties);
          
          const coordinates = e.lngLat;

          const tableHtml = `
            <div style="max-height: 320px; overflow-y: auto;">
              <table style="width: 100%; border-collapse: collapse; color: white;">
                <thead>
                  <tr>
                    <th style="border: 1px solid #ddd; padding: 8px;">Selected Item</th>
                    <th style="border: 1px solid #ddd; padding: 8px;">Value</th>
                  </tr>
                </thead>
                <tbody>
                  ${Object.entries(feature.properties)
                    .filter(([key]) => key !== 'gid' && key !== 'color') // Exclude gid and color
                    .map(([key, value]) => `
                      <tr>
                        <td style="border: 1px solid #ddd; padding: 8px;"><strong>${key}</strong></td>
                        <td style="border: 1px solid #ddd; padding: 8px;">${parseFloat(value.toFixed(2))}</td>
                      </tr>
                    `)
                    .join('')}
                </tbody>
              </table>
            </div>
          `;

          new maplibregl.Popup({ className: 'custom-popup' })
            .setLngLat(coordinates)
            .setHTML(tableHtml)
            .addTo(map);

          if (onClickFeature) {
            onClickFeature(feature);
          }
        }
      });

    });


    // Fit map bounds ke fitur
    if (allFeatures.length > 0 && map) {
      const bbox = turf.bbox({
        type: 'FeatureCollection',
        features: allFeatures,
      });

      if (bbox.length === 4) {
        map.fitBounds(bbox as [number, number, number, number], { padding: 200 });
      }
    }
  } catch (e) {
  } finally {
    if (setIsLoading) setIsLoading(false); // Stop loader, ensuring it stops even on error
  }
};

