import React from 'react';
import { ChatAi } from '../input/chat';
// import { Button } from 'antd';
// import Text from '../../../style/styledComponent/Text';
// // import icChart from '../../../asesst/Icons/interactivemap/icon/cart.svg';
// import icAreaSize from '../../../asesst/Icons/interactivemap/icon/scale-card.svg';
// import icGridTotal from '../../../asesst/Icons/interactivemap/icon/grid-card.svg';
// import { IoMdTime } from "react-icons/io";
// import { formatDate } from '../../../component/newmap/helper/date';
// import { formatNumberWithDots } from '../../../component/newmap/helper/formatNumber';
// import useReduxCatalog from '../../../hoc/hooks/useCatalog';
// import { ModalCart } from '../modal/modal.checkout.cart';

export const DrawerChatAi: React.FC<any> = ({ data }) => {
 
    return (
        <React.Fragment>
            {/* Header */}
            <div style={{ marginBottom: '20px', cursor: 'pointer' }}>
                <h2 style={{ color: 'white' }}>Omben AI</h2>
            </div> 
          <ChatAi/>
        </React.Fragment>
    );
};
