import React, { useEffect, useState } from 'react';
import { Button,Empty } from 'antd';
import { FaBriefcase } from 'react-icons/fa'; // Example icon
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import LoaderSideBar from '../../loader/loader.sidebar';
import { IoChevronForwardOutline } from "react-icons/io5";
import { CardMyData } from '../../../component/menu/card.my.data';

export const MyData: React.FC<any> = () => {
  const [activeTab, setActiveTab] = useState('Data');
  const [param, setParam] = useState('data');
  const [showCardMyData, setShowCardMyData] = useState(false);
  const {
    setMyData,
    setDetailMyData,
    detailMydata,
    myData,
    isloading,
    setMetaData
  } = useReduxCatalog();

  const handleTabClick = (params: string) => {
    setActiveTab(params);
    const paramCatalog = params.toLowerCase();
    setParam(paramCatalog);
  };

  const handleCardClick = (item: any) => {
   setDetailMyData(item)
   setShowCardMyData(true)
   setMetaData('')
  };
  
  useEffect(() => {
    setMyData(param);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);
  const renderTabContent = () => {
    if (isloading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <LoaderSideBar />
        </div>
      );
    }
    const isDataNotFound = !myData?.data?.length;
    if (isDataNotFound) {
      return (
          <Empty 
              image={Empty.PRESENTED_IMAGE_SIMPLE} // Replace with your custom image if needed
              description={
                  <>
                      <span style={{ color: 'white', fontWeight: 'bold', fontSize: '14px' }}>
                          No data available
                      </span>
                      <br />
                      <span style={{ color: 'white', fontSize: '12px' }}>
                          Please check back later or explore other categories.
                      </span>
                  </>
              } 
          />
      );
  }
    return myData?.data.map((item: any, index: any) => {
      return (
        <div
          onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
          onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}
          key={index}
          onClick={() => handleCardClick(item?.category)}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: '#142943',
            padding: '5px',
            borderRadius: '12px',
            marginBottom: '10px',
            marginTop: '10px',
            paddingTop:'10px',
            paddingBottom:'10px',
            border: '1px solid black', // Set border color to white
            paddingLeft:'10px',
            paddingRight:'10px',
            cursor: 'pointer',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for 
          }}
        >
          <FaBriefcase size={30} style={{ marginRight: '20px', color: '#fff' }} />
          <div style={{width: "166px"}}>
            <h4 style={{ marginBottom: '10px' }}>{item?.category_alias}</h4>
            <p>{item?.count_product} Products</p>
          </div>
          <IoChevronForwardOutline size={30} style={{ color: '#fff' }} />
        </div>
      );
    });
  };
  return (
    <React.Fragment>
      {/* Back Arrow */}
      <div style={{ marginBottom: '20px', cursor: 'pointer' }}>
        <h2 style={{ color: 'white' }}>My Data</h2>
      </div>

      {/* List Items */}
      <div style={{ display: 'flex', marginBottom: '20px', justifyContent: 'space-between' }}>
        {['Data', 'Insight', 'Survey', 'Other'].map((tab) => (
          <Button
            type="text"
            key={tab}
            id={tab}
            onClick={() => handleTabClick(tab)}
            style={{
              borderRadius: '0%',
              color: activeTab === tab ? '#4A7CF3' : 'white',
              fontWeight: activeTab === tab ? 'bold' : 'normal',
              padding: '0 10px',
              borderBottom: activeTab === tab ? '2px solid #4A7CF3' : 'none',
            }}
          >
            {tab}
          </Button>
        ))}
      </div>
      <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
        {renderTabContent()}
      </div>
      {showCardMyData && <CardMyData data={detailMydata}  />}
    </React.Fragment>
  );
};
