import React, { useState, useRef, useEffect } from 'react';
import {  Input, Avatar, Button, Form, Card, Badge } from 'antd';
import {
    UserOutlined,
    ShoppingCartOutlined,
} from '@ant-design/icons';
import avatarAI from '../../../asesst/Icons/product/ai-image.png';
import useReduxPrompt from '../../../hoc/hooks/usePrompt';
// import Meta from 'antd/es/card/Meta';
import bgInsight from "../../../asesst/Icons/pixel-insight.png";
import iconHeader from '../../../asesst/Icons/interactivemap/icon.svg';
// import { IoIosLock } from "react-icons/io";
import useReduxTransaction from '../../../hoc/hooks/useTransaction';
import useReduxDrawer from '../../../hoc/hooks/useDrawer';
import { ModalBuy, ModalQuote } from '../../modal';
const { TextArea } = Input; // Destrukturisasi TextArea

interface Message {
    id: number;
    text: string;
    isUser: boolean; // Menandai apakah pesan ini dari pengguna atau bukan
}

export const ChatAi: React.FC<any> = () => {
    const [messages, setMessages] = useState<Message[]>(JSON.parse(localStorage.getItem('chatMessages') || '[]'));// eslint-disable-next-line 
    const [message, setMessage] = useState('');
    const [latestUserMessage, setLatestUserMessage] = useState<any>(''); // State untuk menyimpan pesan terbaru dari pengguna
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const { promptDispatch, prompt, isloading, error } = useReduxPrompt();
    const { setDataForm } = useReduxTransaction();
    const { isOpenModalBuy, isOpenModalQuote, ModalQuoteOpen } = useReduxDrawer();

    const handleSendMessage = () => {
        if (message.trim() !== '') {
            const newMessage: Message = {
                id: Date.now(),
                text: message,
                isUser: true, // Menandai bahwa pesan ini dari pengguna
                // eslint-disable-next-line 
            };

            // Menambahkan pesan pengguna ke ar// eslint-disable-next-line ray messages
            setMessages(prevMessages => [...prevMessages, newMessage]);
            // localStorage.setItem('chatMessages', JSON.stringify([...messages, newMessage]));
            setMessage('');

            // Logika untuk menentukan dan menambahkan respons di sini
            getData(message)
            // const cardData = renderCard(); // Fungsi renderCard harus dipanggil di sini untuk mendapatkan data terbaru
            // localStorage.setItem('cardData', JSON.stringify(cardData));
        }
    };

    const onClickOpenModalQuote = (item: any) => {
        setDataForm(item)
        ModalQuoteOpen(true);
        // const [isModalOpen, setIsModalOpen] = useState(false);(false);
    };
    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            handleSendMessage();
            setLatestUserMessage(message.trim());
        }
    };
    

    useEffect(() => {
        const generateResponse = () => {
            if (isloading) {
                return 'Loading...';
            } else {
                if (error) {
                } else if (prompt) {
                    // console.log(prompt);

                    const botMessagesObj = prompt?.chat.reduce((acc: { [x: string]: any; }, item: { type: string; }) => {
                        if (item.type === 'bot') {
                            acc['data'] = item;
                        }
                        return acc;
                    }, {});

                    const newResponse: Message = {
                        id: Date.now() + 1,
                        text: botMessagesObj?.data?.message,
                        isUser: false, // Menandai bahwa pesan ini bukan dari pengguna
                    };

                    // Menambahkan respons ke array messages
                    setMessages(prevMessages => [...prevMessages, newResponse]);
                    setMessage('');
                }

                return 'Selesai';
            }
        };

        generateResponse();
    }, [isloading, error, prompt]);




    const getData = (message: string) => {
        if (message.trim() !== '') {
            // Memeriksa apakah pesan yang akan dikirim sama dengan pesan sebelumnya
            if (message.trim() === latestUserMessage.trim()) {
                // Jika pesan sama dengan pesan sebelumnya, tidak perlu mengirim
                return null;
            }
            promptDispatch(message.trim())

        }
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const renderMessage = (text: any) => {
        if (!text) {
            return null; // Jika pesan kosong atau undefined, kembalikan null
        }
        const boldRegex = /\*\*(.*?)\*\*/g;
        const paragraphs = text.split('\n\n');
        return paragraphs.map((paragraph: any, index: any) => {
            const boldedParagraph = paragraph.replace(boldRegex, '<strong>$1</strong>');
            return <p key={index} dangerouslySetInnerHTML={{ __html: boldedParagraph }}></p>;
        });
    };
    // console.log(prompt.data);

    const renderCard = () => {
        return (
            prompt?.data?.map((item: any) => (
                <div key={item.id} style={{ paddingTop: '10px' }}>
                    <Form.Item className='price-card' key={item.id}>
                        <Badge.Ribbon text={item.status} color={item.status === 'Ready' ? '#56bf52' : '#f5b916'}>
                            <Card
                                style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                bordered={true}
                                hoverable
                            >
                                <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.alias}</span>
                                    <span style={{ fontSize: 12 }}>{item.description}</span>
                                </div>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <div style={{ marginRight: 16 }}>
                                        <img alt="example" src={bgInsight} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                    </div>
                                    <div>
                                        <span style={{ color: '#3489eb', fontSize: 17, fontWeight: 'bold' }}>contact us for more information</span>

                                    </div>
                                </div>
                                <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {
                                        item.status === 'Ready' && item.type === 'data' ? (
                                            <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                <ShoppingCartOutlined /> Request Data
                                            </Button>
                                        ) : item.status === 'Ready' && item.type === 'insight' ? (
                                            <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                <ShoppingCartOutlined /> Request insight
                                            </Button>
                                        ) : item.status === 'Ready' && item.type === 'survey' ? (
                                            <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                <ShoppingCartOutlined /> Request services
                                            </Button>
                                        ) :
                                            (
                                                <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                    <ShoppingCartOutlined /> Pre-order
                                                </Button>
                                            )
                                    }

                                </div>
                                <div>

                                </div>
                            </Card>
                        </Badge.Ribbon>
                    </Form.Item>
                </div>
            ))
        )
    }

    return (
        <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', overflowY: 'auto', maxHeight: '800px', height: '800px' }}>
                {messages.length === 0 && (
                    <div style={{ textAlign: 'center', paddingTop: '50px' }}>
                        <img src={iconHeader} alt="No message" style={{ width: '100px', height: '200px' }} />
                        <p style={{ color: 'white', fontSize: '16px' }}>Under Maintenance!</p>
                    </div>
                )}
                {messages.map((msg) => (
                    <div key={msg.id} style={{ display: 'flex', flexDirection: 'column', alignItems: msg.isUser ? 'flex-end' : 'flex-start' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                            {msg.isUser ? <Avatar icon={<UserOutlined style={{ fontSize: '16px' }} />} /> : <Avatar src={avatarAI} />}
                            <span style={{ marginLeft: '8px', fontWeight: 'bold', fontSize: '12px' }}>{msg.isUser ? 'You' : 'Om Ben'}</span>
                        </div>
                        <div style={{ padding: '8px', borderRadius: '8px', backgroundColor: msg.isUser ? '#1890ff' : '#f0f0f0', color: msg.isUser ? 'white' : 'black', fontSize: '14px' }}>
                            <>
                                {renderMessage(msg.text)}
                                <br />
                                {msg.isUser || renderCard()}
                            </>
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginTop: '120px' }}>
                <TextArea
                disabled
                    style={{
                        borderRadius: '5px',
                        width: '270px',
                        backgroundColor: '#142943', 
                        color: '#fff', 
                        border: '1px solid #ccc', 
                        paddingRight: '40px', 
                        bottom: 30,
                        position: 'absolute',
                    }}
                    autoSize={{ minRows: 4, maxRows: 8 }}
                    placeholder="Message omben..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="custom-textarea"
                />
            </div>
            <ModalBuy modalOpen={isOpenModalBuy} />
            <ModalQuote modalOpen={isOpenModalQuote} />
        </React.Fragment>
    );
};
