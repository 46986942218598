import React from 'react';
import { Space } from 'antd';
import { CardListCatalog } from '../../shared/map/card/card';

export const CardCatalog: React.FC<any> = ({ selectedCategory, location }) => {    
    const cardsData = Object.values(selectedCategory).flat().map((item: any) => (    
    {
        title: item?.product,
        productId: item.product_id,
        description: item?.description,
        price: item.price?.minPrice ,
        carPrice: item.price?.minPrice != null 
        ? `Rp ${item?.price?.minPrice.toLocaleString('id-ID')}` 
        : 'Free',
        size: item?.size < 50 ? '5x5' : '50x50',
        totalpixel: item?.price.total_pixel,
        status: item?.status,
        areaSize:location?.area ,
        output: item?.output_type,
        year: item?.versions?.info,
        address: location?.address,
        id: item?.id,
        readiness: item?.readiness,
        polygonId: item?.polygon_id,
        subData: item?.subdata,
        version: item?.versions,
        typeDataId: item.type_id,
        radius: item.radius,

        centeroid: {
            lattitude: location?.centroid
            [0],
            longitude: location?.centroid
            [1]
        }
    }));
    // console.log(cardsData);
    
    return (
        <React.Fragment>
            <Space
                direction="vertical"
                style={{
                    position: 'absolute',
                    left: '305px',
                    top: '0%',
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                 <CardListCatalog dataCard={cardsData} />
            </Space>
        </React.Fragment>
    );
};
