import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { HiArrowLongRight } from 'react-icons/hi2';
import Container from "../../../../../style/styledComponent/Container";
import Text from "../../../../../style/styledComponent/Text";
import bgBlue from '../../../../../asesst/new/bg-blue.svg';
import bgImage from '../../../../../asesst/new/bg.png';
import { fadein } from '../../../../../style/keyframes';

const SectionHeroMobile: React.FC = () => {
    const [backgroundImage] = useState(bgBlue);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClick = () => {
        navigate('/interactive-map');
    };
    const handleWhatsAppClick = () => {
        window.open('https://wa.me/6282246245824', '_blank');
    };
    const imageContainerStyles: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
        marginTop: '25px',
        height: windowWidth <= 768 ? '200px' : '300px',
    };

    return (
        <Container id='wrapper' data-testid="app" css={{
            position: "relative",
            textAlign: 'center',
            padding: "20px 10%",
            marginTop: '20px',
            backgroundImage: `url(${bgImage})`,
            animation: `1s ease-in 1s 1 both ${fadein}`,
            backgroundSize: "cover",
            "@bp1": {
                padding: "20px 5%",
            }
        }}>
            <div >
                <div style={imageContainerStyles}>
                <div 
                style={{ 
                    width: '100%', 
                    backgroundColor: 'transparent', 
                    position: 'relative' 
                    }}>
                        <img src={backgroundImage} alt="Geospatial"
                         style={{ width: '100%', height: '100%', objectFit: 'cover', padding:'25px', }} />
                    </div>
                </div>
                
                <Text style={{ color: '#333333', fontSize: windowWidth <= 768 ? '34px' : '54px', fontWeight: 800, lineHeight: windowWidth <= 768 ? '40px' : '68px', fontFamily: '"Plus Jakarta Sans", sans-serif' }}>
                    Indonesia in{' '}
                    <span style={{ color: '#316DBA' }}>Square</span>
                </Text>

                <Text 
                style={{ color: '#333333', fontSize:'20px', fontFamily: '"Plus Jakarta Sans", sans-serif', fontWeight: 400, marginTop:'10px', lineHeight: windowWidth <= 768 ? '30px' : '45px', }}>Unlock the Power of Every Square.</Text>

                <Text
                 style={{ 
                    color: '#5A5A5A', 
                    fontSize: '14px', 
                    fontFamily: '"Jakarta Sans", sans-serif', 
                    fontWeight: 400, 
                    padding:'25px',
                    lineHeight: '20px',
                    width: windowWidth <= 768 ? '400px' : '900px',
                }}>Combining advanced mapping techniques, data analysis, and user-friendly interfaces, we aim to empower individuals and organizations to explore, understand, and utilize Indonesia's spatial information effectively.</Text>

                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onClick={handleClick}
                        style={{
                            backgroundColor: '#316DBA',
                            marginRight: '10px',
                            width: '153px',
                            height: '44px',
                            borderRadius: '8px'
                        }}>
                        <span style={{ color: 'white', fontSize: '17px', fontWeight: '700px', fontFamily: '"Jakarta Sans", sans-serif', }}>Try Our Map</span>
                    </Button>
                    <Button
                        onClick={handleWhatsAppClick}
                        style={{
                            backgroundColor: '#fff',
                            color: '#316DBA',
                            width: '153px',
                            height: '44px',
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <span style={{ fontFamily: '"Jakarta Sans", sans-serif', color: '#316DBA', fontSize: '17px', fontWeight: '700px' }}>Contact Us</span>
                        <HiArrowLongRight style={{ marginLeft: '8px' }} />
                    </Button>
                </div>
            </div>

            {/* <div style={floatingCardStyles}>
                <img src={cardImage} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div> */}
        </Container>
    );
}

export default SectionHeroMobile;
