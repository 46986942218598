import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/config.strore';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
    getCatalogAction,
    getCatalogGeometryAction,
    getCatalogLocationAction,
    drawerActionOpen,
    openModalCheckout,
    closeModalCheckout,
    addChart,
    setSelectedItemAction,
    setListCartAction,
    openModalCartAction,
    closeModalCartAction,
    setDataCartAction,
    setMyDataAction,
    setDetailMyDataAction,
    setMetaDataAction,
    setDataLayerGroupAction,
    setColorGroupAction
} from '../action/catalog.action';


const useReduxCatalog = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, null, AnyAction>>(); // Use ThunkDispatch
    const {
        catalog,
        paramsCatalogGeometry,
        location,
        isloading,
        drawActive,
        isModalCheckout,
        addToChart,
        selectedItem,
        listCart,
        isModalCart,
        setCartCheckout,
        myData,
        detailMydata,
        metaData,
        dataLayerGroup,
        colorGroup
    } = useSelector((state: RootState) => state.catalog);

    const setCatalog = async (params: string, geom: any) => {
        dispatch(getCatalogAction(params, geom));
    };
    const setCatalogGeometry = async (geom: any) => {
        dispatch(getCatalogGeometryAction(geom));
    };
    const setCatalogLocation = async (data: any) => {
        dispatch(getCatalogLocationAction(data));
    };
    const setDrawActive = async (isActive: any) => {
        dispatch(drawerActionOpen(isActive));
    };
    const setModalOpenCheckout = async (status: any) => {
        dispatch(openModalCheckout(status));
    };
    const setModalCloseCheckout= async (status: any) => {
        dispatch(closeModalCheckout(status));
    };
    const setChart = async (token: string, data: any) => {
        return await dispatch(addChart(token, data)); // Ensure the action is awaited and 
    };
    const setSelectedItem = async (data: any) => {
        return await dispatch(setSelectedItemAction(data)); // Ensure the action is awaited and 
    };
    const setListCart = async () => {
        return await dispatch(setListCartAction()); // Ensure the action is awaited and 
    };
    const setModalOpenCart = async (status: any) => {
        dispatch(openModalCartAction(status));
    };
    const setModalCloseCart= async (status: any) => {
        dispatch(closeModalCartAction(status));
    };
    const setDataCart = async (data: any) => {
        dispatch(setDataCartAction(data));
    };
    const setMyData = async (params: string) => {
        dispatch(setMyDataAction(params));
    };
    const setDetailMyData = async (params: string) => {
        dispatch(setDetailMyDataAction(params));
    };
    const setMetaData = async (data: string) => {
        dispatch(setMetaDataAction(data));
    };
    const setDataLayerGroup = async (data: any) => {
        dispatch(setDataLayerGroupAction(data));
    };
    const setColorGroup = async (colorGroup:any) => {
        dispatch(setColorGroupAction(colorGroup));
    };
    return {
        setCatalog,
        setCatalogGeometry,
        setCatalogLocation,
        setDrawActive,
        setModalOpenCheckout,
        setModalCloseCheckout,
        setChart,
        setSelectedItem,
        setListCart,
        setModalOpenCart,
        setModalCloseCart,
        setDataCart,
        setMyData,
        setDetailMyData,
        setMetaData,
        setDataLayerGroup,
        setColorGroup,
        catalog,
        location,
        isloading,
        paramsCatalogGeometry,
        drawActive,
        isModalCheckout,
        addToChart,
        selectedItem,
        listCart,
        isModalCart,
        setCartCheckout,
        myData,
        detailMydata,
        metaData,
        dataLayerGroup,
        colorGroup
    }; // Return pixelState as well if needed

}

export default useReduxCatalog;