import { ThunkAction } from 'redux-thunk'
import catalogApi from '../../service/catalog'
import { Action } from 'redux'
import { RootState } from '../store/config.strore';

import {
    setCatalog,
    setCatalogGeometry,
    setCataloglocation,
    setCatalogStart,
    setDrawActive,
    setCloseModalCheckout,
    setOpenModalCheckout,
    setAddToChart,
    setSelectedItem,
    setListCart,
    setCloseModalCart,
    setOpenModalCart,
    setCart,
    setMyData,
    setDetailMyData,
    setMetaData,
    setDataLayerGroup,
    setColorGroup
} from '../store/catalog.store';

export const getCatalogAction = (params: string, RequestBody: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCatalogStart());
        const data: any = await catalogApi.getCatalogApi(params, RequestBody);
        if (data) {
            dispatch(setCatalog(data));
        }

    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};

export const getCatalogGeometryAction = (RequestBody: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCatalogGeometry(RequestBody));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};

export const getCatalogLocationAction = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCataloglocation(data));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};

export const drawerActionOpen = (status: boolean): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setDrawActive(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }

};

export const openModalCheckout = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setOpenModalCheckout(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const closeModalCheckout = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCloseModalCheckout(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const addChart = (token: string, data: any): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        const addchart = await catalogApi.addToChart(token, data);
        dispatch(setAddToChart(addchart));
        return addchart; // Ensure the payment data is returned
    } catch (error) {
        console.error('Error fetching topup data:', error);
        throw error; // Ensure the error is thrown
    }
};
export const setSelectedItemAction = (data: any): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setSelectedItem(data));
        return data; // Ensure the payment data is returned
    } catch (error) {
        console.error('Error set data:', error);
        throw error; // Ensure the error is thrown
    }
};
export const setListCartAction = (): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        const listData = await catalogApi.getCart();
        dispatch(setListCart(listData));
        return listData; // Ensure the payment data is returned
    } catch (error) {
        console.error('Error fetching list Data :', error);
        throw error; // Ensure the error is thrown
    }
};
export const openModalCartAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setOpenModalCart(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const closeModalCartAction = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCloseModalCart(status));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const setDataCartAction = (data: any): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCart(data));
        return data; // Ensure the payment data is returned
    } catch (error) {
        console.error('Error set data:', error);
        throw error; // Ensure the error is thrown
    }
};

export const setMyDataAction = (params: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        const data: any = await catalogApi.getMyDatas(params);
        if (data) {
            dispatch(setMyData(data));
        }

    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const setDetailMyDataAction = (params: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        const data: any = await catalogApi.getDetailMyDatas(params);
        if (data) {
            dispatch(setDetailMyData(data));
        }
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const setMetaDataAction = (data: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {

        dispatch(setMetaData(data));

    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};
export const setDataLayerGroupAction = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setDataLayerGroup(data));
    } catch (error) {
        console.error('Error fetching group layer:', error);
    }
};
export const setColorGroupAction = (colorGroup: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setColorGroup(colorGroup));
    } catch (error) {
        console.error('Error fetching group layer:', error);
    }
};