import React, { useState } from 'react';
import { Button } from 'antd';
import Text from '../../../style/styledComponent/Text';
// import icChart from '../../../asesst/Icons/interactivemap/icon/cart.svg';
import icAreaSize from '../../../asesst/Icons/interactivemap/icon/scale-card.svg';
import icGridTotal from '../../../asesst/Icons/interactivemap/icon/grid-card.svg';
import { IoMdTime } from "react-icons/io";
import { formatDate } from '../../../component/newmap/helper/date';
import { formatNumberWithDots } from '../../../component/newmap/helper/formatNumber';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import { ModalCart } from '../modal/modal.checkout.cart';
interface CatalogCardProps {
    polygon_id: string;
    area: number;
    product: string;
    additional: { size: number | null };
    created_at: string; // Changed to string since dates are often strings from JSON
    subdata: string[]; // Array of subdata
    total_grid: number;
    totalPrice: number;
    currency: string;
    id: string;
    alias: string;
    address: string;
}

interface ChartProps {
    data: CatalogCardProps[];
}

export const DrawerMyChart: React.FC<ChartProps> = ({ data }) => {
    const { setModalOpenCart, isModalCart, setDataCart } = useReduxCatalog();
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const handleCheckout = () => {
        const selectedData = data.filter((item) => selectedItems.includes(item.id));
        setDataCart(selectedData);
        setModalOpenCart(true);
    };

    const handleCheckboxChange = (productId: string) => {
        setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.includes(productId)
                ? prevSelectedItems.filter((id) => id !== productId)
                : [...prevSelectedItems, productId]
        );
    };
    const totalPrices = selectedItems?.reduce((sum, productId) => {
        const product = data.find((item) => item.id === productId);
        return sum + (product ? product.totalPrice : 0);
    }, 0);


    const formattedTotalPrice = totalPrices.toLocaleString('id-ID', {
        style: 'currency',
        currency: 'IDR',
    });
    return (
        <React.Fragment>
            {/* Header */}
            <div style={{ marginBottom: '20px', cursor: 'pointer' }}>
                <h2 style={{ color: 'white' }}>Cart</h2>
            </div>

            {/* List of Products */}
            <div style={{ overflowY: 'auto', maxHeight: '530px' }}>
                {data?.map((item, index) => (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                            marginBottom: '10px',
                        }}
                    >
                        <input
                            type="checkbox"
                            // checked={selectedItems.includes(item.product)}
                            checked={selectedItems.includes(item.id)}

                            onChange={() => handleCheckboxChange(item.id)} // Use `id` to toggle
                            style={{ marginRight: '10px', width: '20px', height: '50px' }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                height: '120px',
                                width: '100%',
                                alignItems: 'center',
                                // border: '1px solid white',
                                backgroundColor: '#142943',
                                padding: '10px',
                                borderRadius: '10px',
                                marginBottom: '5px',
                            }}
                        >
                            {/* Product Information */}
                            <div style={{ flex: 1 }}>
                                <div style={{ fontWeight: 'bold', marginBottom: '10px', display: 'flex', justifyContent: 'space-between', }}>
                                    { item.alias}
                                    <div
                                        style={{
                                            background: 'white',
                                            padding: '5px',
                                            // width: '30px',
                                            height: '20px',
                                            borderRadius: '12px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Text style={{ color: '#316DBA', margin: 0, fontSize: '10px' }}>50x50</Text>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', marginTop: '10px', marginBottom: '5px' }}>
                                    <img alt="example" src={icAreaSize} />
                                    <Text style={{ color: 'white', margin: 0, fontSize: '12px' }}>{formatNumberWithDots(item.area)} m2</Text>
                                    <img alt="example" src={icGridTotal} />
                                    <Text style={{ color: 'white', margin: 0, fontSize: '12px' }}>{formatNumberWithDots(item.total_grid)}</Text>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', marginBottom: '10px' }}>
                                    <IoMdTime />
                                    <Text style={{ color: 'white', margin: 0, fontSize: '12px' }}>{formatDate(item.created_at)}</Text>
                                </div>
                                <div style={{ fontWeight: 'bold', color: 'white' }}>
                                    {item.totalPrice.toLocaleString('id-ID', {
                                        style: 'currency',
                                        currency: 'IDR',
                                    })}
                                </div>
                            </div>

                        </div>
                    </div>
                ))}
            </div>

            {/* Total and Checkout Section */}
            <div
                style={{
                    bottom: 30,
                    width: '270px',
                    position: 'absolute',
                    borderRadius: '10px',
                }}
            >
                <div style={{ marginBottom: '5px', color: 'white' }}>Total</div>
                <div style={{ marginBottom: '5px', color: 'white' }}>
                    {selectedItems.length} Items for
                </div>
                <div style={{ marginBottom: '10px', color: 'white' }}>{formattedTotalPrice}</div>
                <Button
                    onClick={handleCheckout}
                    disabled={selectedItems.length === 0}
                    type="primary"
                    style={{
                        width: '100%',
                        height: '48px',
                        color: 'white',
                        backgroundColor: selectedItems.length === 0 ? 'gray' : '#4A7FC0',
                        borderColor: selectedItems.length === 0 ? 'gray' : '#4A7FC0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '16px',
                        // fontWeight: 700,
                    }}
                >
                    Checkout
                </Button>
            </div>
            <ModalCart
                isModal={isModalCart} />
        </React.Fragment>
    );
};
