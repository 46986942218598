// drawerSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PaymentState {
    data: any | null;
    isloading: boolean; // Add loading property
    fetching: boolean;
    error: boolean;
    dataTopUp: any | null;
    createPayment: any | null
}

const initialState: PaymentState = {
    data: null,
    fetching: false,
    error: false,
    isloading: false, // Initialize loading state
    dataTopUp: null,
    createPayment: null
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    fetchDataStart: (state) => {
      state.isloading = true; // Set loading to true when fetching starts
      state.error = false;
    },
    fetchDataFailure: (state) => {
      state.isloading = false; 
      state.error = true;
    },
    fetchDataSucces: (state, action: PayloadAction<any>) => {
        state.isloading = false; 
        state.data = action.payload;
    },
    createtopUpPayment: (state, action: PayloadAction<any>) => {
      state.isloading = false; 
      state.dataTopUp = action.payload;
  },
  createPaymentV2: (state, action: PayloadAction<any>) => {
    state.isloading = false; 
    state.createPayment = action.payload;
},
  },
});

export const { 
    fetchDataSucces,
    fetchDataStart,
    fetchDataFailure,
    createtopUpPayment,
    createPaymentV2
} = paymentSlice.actions;
export default paymentSlice.reducer;
