import {api} from './api';

const postPayment = async (body: any): Promise<any> => {
    const url: string = 'api/xendit/create-invoice'
    
    try {
        const { data } = await api.post<any>(url, body);
        // console.log(data);
        return data;
    } catch (error) {
        console.error('Error occurred while submit payment:', error);
        throw error;
    }
};

const topUpPayment = async (token:string, body: any): Promise<any> => {
    const url: string = 'api/xendit/top-up'
    try {
        const { data } = await api.post<any>(url, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return data;
    } catch (error) {
        console.error('Error occurred while submit payment:', error);
        throw error;
    }
};

const createPaymentV2 = async (body: any): Promise<any> => {
    const url: string = 'api/v2/xendit/create-invoice'
    
    try {
        const { data } = await api.post<any>(url, body);
        // console.log(data);
        return data;
    } catch (error) {
        console.error('Error occurred while submit payment:', error);
        throw error;
    }
};

const ombenApi = {
    postPayment,
    topUpPayment,
    createPaymentV2
};

export default ombenApi;
