import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import iconPolygon from '../../../asesst/Icons/interactivemap/icon/polygon.svg';
import iconAi from '../../../asesst/Icons/interactivemap/icon/ai.svg';
import { renderGeocodeResultToMap, GeoJSONPolygon, GeoJSONFeature } from '../../../component/newmap/helper/map/geocode';
import { GeocodingControl } from '@maptiler/geocoding-control/react';
import { Feature, Geometry } from 'geojson';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { handleDrawClick, initializeMapboxDraw, removeDrawControl } from '../../../component/newmap/helper/map/draw.polygon';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import { handleReverseGeocode } from '../../../component/newmap/helper/map/reversecode';

interface DrawerMenuCatalogProps {
    map: maplibregl.Map | null; // Pass the map instance as a prop
}

export const DrawerMenuCatalog: React.FC<DrawerMenuCatalogProps> = ({ map }) => {
    const { setCatalogGeometry, setCatalogLocation } = useReduxCatalog();
    const [draw] = useState<MapboxDraw>(initializeMapboxDraw);
    const [isDrawActive, setIsDrawActive] = useState<boolean>(false); // State to track draw control

    const handleGeocodingSelect = async (result: Feature<Geometry> | undefined) => {
        if (result?.geometry?.type === 'Polygon' && map) {
            const geojson: GeoJSONFeature = {
                type: 'Feature', // Type must be explicitly "Feature"
                geometry: result.geometry as GeoJSONPolygon, // Ensure geometry matches GeoJSONPolygon
                properties: {}, // Empty properties for now, can be extended
            };
            renderGeocodeResultToMap(map, geojson);
            setCatalogGeometry(geojson.geometry);
            try {
                const locationCode = await handleReverseGeocode(geojson.geometry);  
                setCatalogLocation(locationCode);
            } catch (error) {
                console.error('Error fetching address:', error);
            }
        }
    };

    const handleDrawPolygon = () => {
        if (isDrawActive) {
            // If draw control is active, remove it on button click
            removeDrawControl(map, draw);
            map?.off('draw.create', handleDrawCreate); // Remove event listener
        } else {
            // If draw control is not active, add it
            handleDrawClick(map, draw);
            map?.on('draw.create', handleDrawCreate); // Add event listener
        }
        setIsDrawActive(!isDrawActive); // Toggle draw control state
    };

    const handleDrawCreate = async (event: any) => {
        const polygon = event.features[0].geometry;
        setCatalogGeometry(polygon);
        try {
            const locationCode = await handleReverseGeocode(polygon);
            setCatalogLocation(locationCode);
        } catch (error) {
            console.error('Error fetching address:', error);
        }
    };

    useEffect(() => {
        // Cleanup logic has been removed since you only want to remove it on button click
    }, [map, draw]);

    return (
        <React.Fragment>
            {/* Title */}
            <h2 style={{ color: 'white' }}>Catalogue</h2>

            {/* Section: Select Location */}
            <div style={{ marginBottom: '30px', marginTop: '20px' }}>
                <h3 style={{ color: 'white', marginTop: '20px' }}>Select Location</h3>
                <p style={{ color: 'rgba(255, 255, 255, 0.65)', marginTop: '10px', marginBottom: '20px' }}>
                    Select a location based on the available area or use a custom polygon to determine the area according to your needs.
                </p>
                <label style={{ color: 'white', marginBottom: '8px', display: 'block' }}>
                    Search Location
                </label>

                <GeocodingControl
                    onPick={handleGeocodingSelect}
                    apiKey={'IkttUJmMfqWCx0g43vGM'}
                />
            </div>

            {/* Section: Custom Polygon */}
            <div style={{ marginBottom: '20px' }}>
                <label style={{ color: 'white', marginBottom: '10px', display: 'block' }}>
                    Custom Polygon
                </label>
                <Button
                    type="primary"
                    icon={<img src={iconPolygon} alt="custom polygon icon" />}
                    style={{
                        width: '100%',
                        height: '48px',
                        borderRadius: '8px',
                        backgroundColor: '#4A7FC0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '16px', fontWeight: 700,
                    }}
                    onClick={handleDrawPolygon}
                >
                    {isDrawActive ? 'Remove Polygon' : 'Draw Custom Polygon'}
                </Button>
            </div>

            {/* Section: AI Assistance */}
            <div style={{ marginBottom: '20px', marginTop: '10px' }}>
                <label style={{ color: 'white', marginBottom: '8px', display: 'block' }}>
                    Bantuan AI
                </label>
                <p style={{ color: 'rgba(255, 255, 255, 0.65)', marginBottom: '10px' }}>
                    Bingung dengan pilihan diatas? Cobain chat dengan bantuan AI, sebutkan kebutuhanmu dan AI akan memberikan rekomendasi yang sesuai kebutuhanmu.
                </p>
                <Button
                    type="primary"
                    icon={<img src={iconAi} alt="bantuan AI" />}
                    style={{
                        width: '100%',
                        height: '48px',
                        borderRadius: '8px',
                        backgroundColor: '#4A7FC0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 700,
                    }}
                >
                    Chat dengan AI
                </Button>
            </div>
        </React.Fragment>
    );
};
