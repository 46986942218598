import React from 'react';
import { Space,Button, Tooltip} from 'antd';
import layer from '../../../asesst/Icons/interactivemap/icon/layer-tools.svg'

export const LayerTools: React.FC<any> = () => {
    return (
        <React.Fragment>
            <Space
                direction='vertical'
                style={{
                    position: 'absolute',
                    right: '15px',
                    bottom: 190,
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <Tooltip 
                    title={<p style={{color:'black'}}>location</p>}
					placement="right"
					color='white'
                    >
                    <Button
                       id='end-point'
                        style={{
                            backgroundColor: 'white', // Ubah warna tombol menjadi putih
                            color: 'black', // Ubah warna teks menjadi hitam
                            height: "50px",
                            width: "50px",
                            borderBottomLeftRadius:'0px',
                            borderBottomRightRadius:'0px',
                            borderTopLeftRadius:'12px',
                            borderTopRightRadius:'12px',
                            display: 'flex',
                            justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                            alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                        }}
                     
                    >
                    <img 
                        src={layer}
                        alt="Button Icon" 
                    />
                    </Button>
                </Tooltip>
            </Space>
        </React.Fragment>
    )
}