import React, { useEffect } from 'react';
import { Space } from 'antd';
import { ToogleMenu } from '../../shared/drawtools/toogle.menu';
import { DrawerChatAi } from '../../shared/map/drawer/drawer.chatAI';
import useReduxCatalog from '../../hoc/hooks/useCatalog';

export const MenuChatAi: React.FC<any> = () => {
    const {
        setMetaData,
        setDataLayerGroup
    } = useReduxCatalog();

    useEffect(()=> {
        setMetaData(''); // Reset the geometry to null
        setDataLayerGroup([])
        // eslint-disable-next-line
    },[])
    return (
        <React.Fragment>
            <Space
                direction="vertical"
                style={{
                    position: 'absolute',
                    left: '120px',
                    top: '3%',
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <div
                    style={{
                        background: '#1E334D',
                        border: '1px #d9d9d9',
                        color: 'white',
                        borderRadius: '12px',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                        width: '300px',
                        padding: '15px',
                        height: 'calc(100vh - 50px)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}
                >
                   <DrawerChatAi/>

                    <ToogleMenu />
                </div>

            </Space>
        </React.Fragment>
    );
};
