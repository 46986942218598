import React, { useRef, useEffect, useState } from 'react';
import 'maplibre-gl/dist/maplibre-gl.css';
import LoaderMap from '../../shared/loader/loaderMap';
import maplibregl from 'maplibre-gl';
// import { getEsriWorldImageryStyle } from './helper/layer/layer.tile';
import { SideMenu } from '../sider/side.menu';
import { LocationTools } from '../../shared/map/button/location';
import { ZoomTools } from '../../shared/map/button/zoom';
import { LayerTools } from '../../shared/map/button/layer';
import useReduxCatalog from '../../hoc/hooks/useCatalog';
import { addGeoJsonLayerParquet } from './helper/map/geojson/layer';
import { TabsSeries } from '../../shared/map/tabs/tabs.series';
import { DrawerGroupLayer } from '../../shared/map/drawer/drawer.group.layer';
import { DrawerGroupColor } from '../../shared/map/drawer/drawer.group.color';
// import { DrawerGroupScore } from '../../shared/map/drawer/drawer.group.score';
export const MapComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<maplibregl.Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  // const [dataLayerGroup, setDataLayerGroup] = useState<dataLayerGroup[]>([]);
  const [dataSeries, setDataSeries] = useState('Total');
  const [isSeries, setIsSeries] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    metaData,
    dataLayerGroup,
    colorGroup,
    setDataLayerGroup,
  } = useReduxCatalog();
  useEffect(() => {
    if (map.current || !mapContainer.current) return;

    map.current = new maplibregl.Map({
      container: mapContainer.current,
      // style: getEsriWorldImageryStyle(),  // Use the imported style function
      style: 'https://api.maptiler.com/maps/streets/style.json?key=IkttUJmMfqWCx0g43vGM',
      center: [114.17487380816897, 0.3746499288735805],
      zoom: 4,
      maxZoom: 17
    });

    map.current.on('load', () => {
      setMapLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (!mapLoaded || !map.current) return;
    addGeoJsonLayerParquet(
      map.current,
      metaData?.table_location,
      metaData?.metadata_location,
      setDataLayerGroup,
      dataSeries,
      setIsSeries,
      colorGroup,
      undefined,
      0.5,
      setIsLoading,
    );
    // eslint-disable-next-line
  }, [mapLoaded, metaData, dataSeries,colorGroup]);

  return (
    <div>
      <div ref={mapContainer} style={{ width: '100%', height: '100vh' }} />
      {isLoading && <LoaderMap />}
      <SideMenu map={map.current} />
      {children}
      <LocationTools />
      <ZoomTools />
      <LayerTools />
      {isSeries && dataLayerGroup?.length > 0 && (
        <TabsSeries setDataSeries={setDataSeries} />
      )}
      {dataLayerGroup?.length > 0 && (
        <DrawerGroupColor />
      )}
      {dataLayerGroup?.length > 0 && (
        <DrawerGroupLayer dataLayerGroup={dataLayerGroup} map={map.current!} />
      )}
    </div>
  );
};
