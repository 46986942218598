import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FaUsers } from 'react-icons/fa';
import { MdOutlineFlood, MdOutlineForest } from 'react-icons/md';
import { RiGraduationCapLine } from 'react-icons/ri';
import { HiArrowLongRight } from 'react-icons/hi2';
import Container from "../../../../style/styledComponent/Container";
import Text from "../../../../style/styledComponent/Text";
import bgBlue from '../../../../asesst/new/bg-blue.svg';
import bgRed from '../../../../asesst/new/Group 236.svg';
import bgGreen from '../../../../asesst/new/Group 236 (2).svg';
import bgBlack from '../../../../asesst/new/Group 236 (1).svg';
import cardBlue from '../../../../asesst/new/Frame 14.svg';
import cardRed from '../../../../asesst/new/Frame 14 (4).svg';
import bgImage from '../../../../asesst/new/bg.png';
import cardBlack from '../../../../asesst/new/Frame 14 (3).svg';
import cardGreen from '../../../../asesst/new/Frame 14 (2).svg';
import { fadein } from '../../../../style/keyframes';

const SectionHero: React.FC = () => {
    const [backgroundImage, setBackgroundImage] = useState(bgBlue);
    const [cardImage, setCardImage] = useState(cardBlue);
    const [activeButton, setActiveButton] = useState('Population');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClick = () => {
        navigate('/interactive-map');
    };
    const handleWhatsAppClick = () => {
        window.open('https://wa.me/6282246245824', '_blank');
    };
    const handleButtonClick = (bgImage: string, cardImage: string, buttonActive: string) => {
        setBackgroundImage(bgImage);
        setCardImage(cardImage);
        setActiveButton(buttonActive);
    };

    const buttonStyles = (buttonActive: string): React.CSSProperties => ({
        margin: '0 10px',
        display: 'flex',
        alignItems: 'center',
        width: '115px',
        height: '32px',
        marginTop: '10px',
        fontSize: '10px',
        fontWeight: 700,
        backgroundColor: activeButton === buttonActive ? '#C4DCFD' : '#fff',
        color: activeButton === buttonActive ? '#4B83D9' : '#000',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    });

    const imageContainerStyles: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
        marginTop: '25px',
        height: windowWidth <= 768 ? '200px' : '300px',
    };

    const floatingCardStyles: React.CSSProperties = {
        width: '184px',
        height: '158px',
        backgroundColor: '#fff',
        position: 'absolute',
        top: windowWidth <= 768 ? '100px' : '130px',
        right: windowWidth <= 768 ? '5%' : '7%',
    };

    return (
        <Container id='wrapper' data-testid="app" css={{
            position: "relative",
            textAlign: 'center',
            padding: "20px 10%",
            marginTop: '20px',
            backgroundImage: `url(${bgImage})`,
            animation: `1s ease-in 1s 1 both ${fadein}`,
            backgroundSize: "cover",
            "@bp1": {
                padding: "20px 5%",
            }
        }}>
            <div style={{ margin: '0 auto' }}>
                <div style={imageContainerStyles}>
                    <div style={{ width: '100%', backgroundColor: '#fff', position: 'relative' }}>
                        <img src={backgroundImage} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                </div>
                <div style={{ position: 'relative', left: '50%', marginTop: '20px', marginBottom: '20px', transform: 'translateX(-50%)', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <Button
                        onClick={() => handleButtonClick(bgBlue, cardBlue, 'Population')}
                        style={buttonStyles('Population')}
                        icon={<FaUsers size={24} />}>
                        <span style={{ fontFamily: '"Jakarta Sans", sans-serif', fontSize: '10px', fontWeight: 700 }}>Population</span>
                    </Button>
                    <Button
                        onClick={() => handleButtonClick(bgGreen, cardGreen, 'Forest')}
                        style={buttonStyles('Forest')}
                        icon={<MdOutlineForest size={24} />}>
                        <span style={{ fontFamily: '"Jakarta Sans", sans-serif', fontSize: '10px', fontWeight: 700 }}>Forest</span>
                    </Button>
                    <Button
                        onClick={() => handleButtonClick(bgBlack, cardBlack, 'Education')}
                        style={buttonStyles('Education')}
                        icon={<RiGraduationCapLine size={24} />}>
                        <span style={{ fontFamily: '"Jakarta Sans", sans-serif', fontSize: '10px', fontWeight: 700 }}>Education</span>
                    </Button>
                    <Button
                        onClick={() => handleButtonClick(bgRed, cardRed, 'Flood')}
                        style={buttonStyles('Flood')}
                        icon={<MdOutlineFlood size={24} />}>
                        <span style={{ fontFamily: '"Jakarta Sans", sans-serif', fontSize: '10px', fontWeight: 700 }}>Flood</span>
                    </Button>
                </div>

                <Text style={{ color: '#333333', fontSize: windowWidth <= 768 ? '34px' : '54px', fontWeight: 800, lineHeight: windowWidth <= 768 ? '40px' : '68px', fontFamily: '"Plus Jakarta Sans", sans-serif' }}>
                    Indonesia in{' '}
                    <span style={{ color: '#316DBA' }}>Square</span>
                </Text>

                <Text style={{ color: '#333333', fontSize: '26px', fontFamily: '"Plus Jakarta Sans", sans-serif', fontWeight: 400, marginTop: windowWidth <= 768 ? '20px' : '0px', lineHeight: windowWidth <= 768 ? '30px' : '45px', }}>Unlock the Power of Every Square.</Text>

                <Text
                 style={{ 
                    color: '#5A5A5A', 
                    fontSize: '16px', 
                    fontFamily: '"Jakarta Sans", sans-serif', 
                    fontWeight: 400, 
                    marginTop: windowWidth <= 768 ? '20px' : '10px',
                    lineHeight: windowWidth <= 768 ? '30px' : '26px',
                    width: windowWidth <= 768 ? '400px' : '900px',
                }}>Combining advanced mapping techniques, data analysis, and user-friendly interfaces, we aim to empower individuals and organizations to explore, understand, and utilize Indonesia's spatial information effectively.</Text>

                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onClick={handleClick}
                        style={{
                            backgroundColor: '#316DBA',
                            marginRight: '10px',
                            width: '153px',
                            height: '44px',
                            borderRadius: '8px'
                        }}>
                        <span style={{ color: 'white', fontSize: '17px', fontWeight: '700px', fontFamily: '"Jakarta Sans", sans-serif', }}>Try Our Map</span>
                    </Button>
                    <Button
                        onClick={handleWhatsAppClick}
                        style={{
                            backgroundColor: '#fff',
                            color: '#316DBA',
                            width: '153px',
                            height: '44px',
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <span style={{ fontFamily: '"Jakarta Sans", sans-serif', color: '#316DBA', fontSize: '17px', fontWeight: '700px' }}>Contact Us</span>
                        <HiArrowLongRight style={{ marginLeft: '8px' }} />
                    </Button>
                </div>
            </div>

            <div style={floatingCardStyles}>
                <img src={cardImage} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
        </Container>
    );
}

export default SectionHero;
