import React, { useEffect, useState } from 'react';
import { Space, Typography } from 'antd';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
const { Text } = Typography;

const colorsblue = ['#4A7FC0', '#4A7FC0', '#4A7FC0', '#4A7FC0', '#4A7FC0', '#4A7FC0']; // Dynamic blue color
const colorsred = ['#F04A41', '#F04A41', '#F04A41', '#F04A41', '#F04A41', '#F04A41']; // Dynamic blue color
const colorsgreen = ['#5CBA60', '#5CBA60', '#5CBA60', '#5CBA60', '#5CBA60', '#5CBA60']; // Dynamic blue color
const gradientColors = ['#6CA380', '#a0b882', '#d3cb84', '#e1a76d', '#f09c5e', '#f48752']; // Six color gradient

const colorsblueParams = [  '#DCE6F3', '#B8CDE8', '#94B4DD', '#709BD2', '#4A7FC0', 
'#426FAC', '#3A6099', '#325186', '#2A4273', '#1E315E']; // Dynamic blue color
const colorsredParams = [        '#FCD6D3', '#FAB3B0', '#F7918D', '#F56E6B', '#F34B48', 
'#F04A41', '#DA3931', '#C42823', '#AE1816', '#980908'
]; // Dynamic blue color
const colorsgreenParams = ['#A8D9A1', '#97C98C', '#86B77D', '#75A06E', '#649B5F', 
'#528A4F', '#417B40', '#306C31', '#1F5D22', '#5CBA60']; // Dynamic blue color
const gradientColorsParams = ['#6CA380', '#a0b882', '#d3cb84', '#e1a76d', '#f09c5e', '#f48752', '#ec624a', '#d85546', '#c64f36', '#c64f36']; // Six color gradient

export const DrawerGroupColor: React.FC<any> = () => {
    const {
        setColorGroup
    } = useReduxCatalog();
    const [currentColors, setCurrentColors] = useState({
        type: 'gradient',
        color: gradientColorsParams, // Default gradient colors
    });


    const handleColorClick = (colorGroup: string) => {
        if (colorGroup === 'gradient') {
            setCurrentColors({
                type: colorGroup,
                color: gradientColorsParams, // Set to new gradient colors
            });
        }
        if (colorGroup === 'green') {
            setCurrentColors({
                type: colorGroup,
                color: colorsgreenParams, // Set to new gradient colors
            });
        }
        if (colorGroup === 'blue') {
            setCurrentColors({
                type: colorGroup,
                color: colorsblueParams, // Set to new gradient colors
            });
        }
        if (colorGroup === 'red') {
            setCurrentColors({
                type: colorGroup,
                color: colorsredParams, // Set to new gradient colors
            });
        }
    };

    useEffect(() => {
        setColorGroup(currentColors)
        // eslint-disable-next-line
    }, [currentColors])

    return (
        <React.Fragment>
            <Space
                direction="vertical"
                style={{
                    position: 'absolute',
                    right: '10px',
                    top: 20,
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <div
                    style={{
                        background: '#142942',
                        border: '1px solid #d9d9d9',
                        color: 'white',
                        borderRadius: '8px',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                        width: 'auto',
                        maxHeight: '150px',
                        overflowY: 'scroll',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'sticky', top: 0, background: '#142942', padding: '8px', zIndex: 101, marginRight: '10px' }}>

                        <Text style={{ width: '165px', color: 'white', fontWeight: 'bolder' }}>Change Color</Text>
                    </div>

                    {/* Object.keys(groupedData).map((data, index) => ( */}
                    {/* {dataLayerGroup.map((layer: any, index: any) => ( */}
                    <div
                        onClick={() => handleColorClick('blue')}
                        onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                        onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}
                        style={{
                            cursor: 'pointer',
                            marginTop: '5px',
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            display: 'flex', alignItems: 'center', marginLeft: '12px', marginRight: '12px', marginBottom: '15px'
                        }}>
                        {colorsblue?.map((color, index) => (
                            <div

                                key={index}
                                style={{
                                    width: '30px',
                                    height: '10px',
                                    borderRadius: '10px',
                                    backgroundColor: color,
                                    marginRight: '5px',
                                }}
                            />
                        ))}
                    </div>
                    <div
                        onClick={() => handleColorClick('red')}
                        onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                        onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}
                        style={{
                            cursor: 'pointer',
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            display: 'flex', alignItems: 'center', marginLeft: '12px', marginRight: '12px', marginBottom: '15px'
                        }}>
                        {colorsred?.map((color, index) => (
                            <div

                                key={index}
                                style={{
                                    width: '30px',
                                    height: '10px',
                                    borderRadius: '10px',
                                    backgroundColor: color,
                                    marginRight: '5px',
                                }}
                            />
                        ))}
                    </div>
                    <div
                        onClick={() => handleColorClick('green')}
                        onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                        onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}
                        style={{
                            cursor: 'pointer',
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            display: 'flex', alignItems: 'center', marginLeft: '12px', marginRight: '12px', marginBottom: '15px'
                        }}>
                        {colorsgreen?.map((color, index) => (
                            <div

                                key={index}
                                style={{
                                    width: '30px',
                                    height: '10px',
                                    borderRadius: '10px',
                                    backgroundColor: color,
                                    marginRight: '5px',
                                }}
                            />
                        ))}
                    </div>
                    <div
                        onClick={() => handleColorClick('gradient')}
                        onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                        onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}
                        style={{
                            cursor: 'pointer',
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            display: 'flex', alignItems: 'center', marginLeft: '12px', marginRight: '12px', marginBottom: '15px'
                        }}>
                        {gradientColors?.map((color, index) => (
                            <div
                                key={index}
                                style={{
                                    width: '30px',
                                    height: '10px',
                                    borderRadius: '10px',
                                    backgroundColor: color,
                                    marginRight: '5px',
                                }}
                            />
                        ))}
                    </div>
                </div>
            </Space>
        </React.Fragment>
    );
};
