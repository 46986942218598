import * as turf from '@turf/turf';

export const getReverseGeocode = async (longitude: number, latitude: number) => {
    const response = await fetch(
        `https://api.maptiler.com/geocoding/${longitude},${latitude}.json?key=IkttUJmMfqWCx0g43vGM`
    );
    const data = await response.json();
    return data;
};

export const calculateCentroid = (polygon: GeoJSON.Geometry): [number, number] | null => {
    if (polygon.type === 'Polygon') {
        const centroid = turf.centroid(polygon as turf.helpers.Polygon);
        return centroid.geometry.coordinates as [number, number];
    }
    return null;
};

export const formatNumberWithSeparators = (num: number): string => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const calculateAreaInSquareKm = (polygon: GeoJSON.Geometry): number | null => {
    if (polygon.type === 'Polygon') {
        const areaInSquareMeters = turf.area(polygon as turf.helpers.Polygon);
        // const areaInSquareKm = areaInSquareMeters / 1_000_000; // Convert to square kilometers
        return Math.round(areaInSquareMeters); // Round to the nearest whole number
    }
    return null;
};
export const handleReverseGeocode = async (geometry: GeoJSON.Geometry) => {
    if (geometry.type === 'Polygon') {
        const centroid = calculateCentroid(geometry);
        const area = calculateAreaInSquareKm(geometry); // Calculate the area

        if (centroid) {
            const [longitude, latitude] = centroid;
            const geocodeData = await getReverseGeocode(longitude, latitude);
            const addressFull = geocodeData.features[0]?.place_name;
            const segments = addressFull.split(',');  // Split the string by commas
            const address = segments.slice(-2).join(',').trim()  // Get the last two segments
            // return lastTwoSegments.join(',').trim();  // Join them and trim any extra spaces
            
            return {
                address,
                centroid,
                area, // Include the area in the result
            };
        }
    }
    return null;
};
