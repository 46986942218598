import React, { useEffect } from 'react';
import { Space, Empty } from 'antd';
import { DrawerMyChart } from '../../shared/map/drawer/drawer.chart';
import { ToogleMenu } from '../../shared/drawtools/toogle.menu';
import useReduxCatalog from '../../hoc/hooks/useCatalog';
import { v4 as uuidv4 } from 'uuid';
import noData from '../../asesst/new/nodata.svg'; // Replace with your image path


export const MenuChartCatalog: React.FC<any> = () => {
    const { listCart, setListCart } = useReduxCatalog();

    useEffect(() => {
        const fetchData = async () => {
            const response = await setListCart();  // Assuming setListCart returns the response
            if (response?.statusCode === 200) {
                // console.log('Data fetched successfully:');
            } else {
                // console.error('Error fetching data:');
            }
        };

        fetchData();  // Call the async function to fetch the data
        // eslint-disable-next-line
    }, []);

    // Flatten and add unique IDs to the data
    const groupedData = listCart?.data?.flatMap((item: { dataIds: any[]; polygon_id: any; }) =>
        item.dataIds.map((dataId: any) => ({
            polygon_id: item.polygon_id,
            ...dataId,
            id: uuidv4(), // Adding a unique ID to each object
        }))
    );

    // Conditional rendering based on whether listCart is empty or not
    const isDataNotFound = !listCart?.data?.length;

    return (
        <React.Fragment>
            <Space
                direction="vertical"
                style={{
                    position: 'absolute',
                    left: '120px',
                    top: '3%',
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <div
                    style={{
                        background: '#1E334D',
                        border: '1px #d9d9d9',
                        color: 'white',
                        borderRadius: '12px',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                        width: '300px',
                        padding: '10px',
                        height: 'calc(100vh - 50px)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}
                >
                    {isDataNotFound ? (
                          <div
                          style={{
                              display: 'flex',
                              height: '150px',
                              width: '100%',
                              alignItems: 'center',
                              justifyContent:'center',
                            //   border: '1px solid white',
                            //   backgroundColor: '#142943',
                            //   padding: '10px',
                            //   borderRadius: '10px',
                              marginBottom: '5px',
                          }}
                      >
                       <Empty 
                            image={noData}
                            description={
                            <>
                            <span style={{ color: 'white', fontWeight: 'bold', fontSize:'14px' }}>There is no data in the cart yet</span>
                            <br></br>
                            <span style={{ color: 'white', fontSize:'12px' }}>Explore the catalog to see the data you need.</span>
                            </>
                        } 
                            />

                        </div>
                    ) : (
                        <>
                            <DrawerMyChart data={groupedData} />
                            <ToogleMenu />
                        </>
                    )}
                </div>
            </Space>
        </React.Fragment>
    );
};
